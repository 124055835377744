* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    background-color: rgb(234, 237, 237);
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}
