.container-wrapper {
    padding: 2rem 0;
    max-width: 432px;
    margin: 0 auto;
    width: 100%;
}

.button-wrapper {
    padding: 3px 0;
}
